.wrapper {
    background-image: url("images/bd.jpg");
    background-size: cover;
    width: 100vw;
    height: 100vh;
    background-position: center top;
    overflow-x: hidden;
}

.cta-wrapper {
    position: relative;
    display: flex;
    width: 100vw;
    min-height: 550px;

}

.groups {
    width: 95%;
    min-height: 550px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.cta-left, .cta-right {
    width: 50%;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.cta-left {
    color: white;
}

.phrase {
    font-family: 'Caprasimo', cursive;
    font-size: 3.4rem;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 1);
    color: lightblue;
}

.group {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 400px;
    background-color: white;
    border-radius: 100px;
    margin: 10px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
}

.group-logo {
    height: 100%;
}

.wrapper-logo {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    height: 150px;

}

.group-title {
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
}

.cta {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 1);

}

.cta-btn {
    background-color: white;
    color: black;
    padding: 10px;
    margin-top: 30px;
    border-radius: 15px;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    text-align: center;
}

.blurb-wrapper {
    background-color: white;
    padding: 10px;
    border: 10px solid lightblue;
    border-radius: 20px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    width: 40%;
    position: relative;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.blurb-image-wrapper {
    height: 180px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.blurb-image {
    position: relative;
    height: 70%;
    border: 5px solid lightblue;
    border-radius: 100px;
    margin-bottom: 10px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
}

.blurb-text {
    font-size: 15px
}

.spacing {
    border: 1px solid black;
    width: 100px;
    height: 50px;
}

.services-wrapper {
    width: 98vw;
    height: 500px;
    margin-top: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.services-tier {
    width: 40vw;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;

}

.services-tier-title {
    font-size: 60px;
    padding: 10px;
    text-align: center;
    font-family: 'Caprasimo', cursive;
    border-radius: 10px;
    display: inline-block;
    color: lightblue;
    text-shadow: 3px 3px 2px rgba(0, 0, 0, 1);
}

.services-tier-1, .services-tier-2 {
    width: 45%;
    border-radius: 10px;
    background-color: white;
    margin-top: 20px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.47);
    min-height: 200px;
    min-width: 220px;
}

.services-tier-2 {
    margin-bottom: 20px;
}

.tier-title {
    font-family: 'Caprasimo', cursive;
    font-size: 30px;
    text-align: center;
}

.tier-list-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: lighter;
}

li {
    margin-bottom: 5px;
}

.estimates {
    font-family: 'Caprasimo', cursive;
    color: red;
    font-size: 20px;
    text-align: center;
}


@media screen and (max-width: 1330px) {
    .phrase {
        font-size: 3rem;
    }
}

@media screen and (max-width: 1175px) {
    .phrase {
        text-align: center;
    }

    .cta-left {
        width: 45%;
    }

    .cta-right {
        width: 55%;
    }

    .group {
        width: 30%;
    }

    .services-tier {
        width: 50vw;
    }
}

@media screen and (max-width: 1000px) {
    .cta-wrapper {
        flex-direction: column;
    }

    .cta-left {
        margin-top: 10%;
    }

    .cta-left, .cta-right {
        width: 100%;
        height: 400px;
    }

    .blurb-wrapper {
        width: 60%;
    }

    .cta-right {
        margin-bottom: 30px;
    }

    .services-tier {
        width: 65vw;
    }

}

@media screen and (max-width: 700px) {
    .group {
        width: 40%;

    }

    .services-tier {
        flex-direction: column;
    }
}

@media screen and (max-width: 668px) {
    .cta-wrapper {
        margin-bottom: 15px;
    }

    .group {
        width: 70%;
    }
}